import { SupportedLanguages } from 'configs/translation';
import { IAdditions, IView, IViewWidgets } from 'features/settings/editor/residents-editor/interfaces';
import * as uuid from 'uuid';
import parseForAdmin from 'features/settings/editor/residents-editor/utils/parse-for-admin';
import {
  IBirthdayWidget,
} from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';
import parseForMobile from 'features/settings/editor/residents-editor/utils/parse-for-mobile';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import { defaultProfile } from 'features/settings/mobile-editor/default-profile';
import { request } from '~/utils/request';

export let TranslatesList: Record<string, string> = {};
export let AdditionsList: IAdditions[] | undefined = [];
/**
 * Получение переводов
 * */
export const translatesGet = request.custom<void>(async ({ api, data }) => {
  try {
    const query = data as typeof SupportedLanguages[keyof typeof SupportedLanguages] || 'ru';
    const res = await api.get<any>(`/api/admin/translations/list?locale=${query}&domain=profile_view`);
    TranslatesList = res.data.data;

    return {
      data: res.data.data,
    };
  } catch (e) {
    return { error: e };
  }
});
/**
 * Получение всех существующих additional полей
 * */
export const additionsGet = request.custom<IAdditions[]>(async ({ api }) => {
  try {
    const res = await api.get<{ data: IAdditions[] }>('/api/admin/additional_fields/list?entityType=user');
    AdditionsList = res.data?.data;

    return { data: res.data?.data };
  } catch (e) {
    return { error: e };
  }
});

/**
 * Получение view(мп)
 * */
const viewGet = request.custom<IView>(async ({ api }) => {
  try {
    const res = await api.get<{ data: IView }>(
      '/api/admin/view?code=user_profile',
    );

    /**
     * Поле birthday может быть старой версии.
     * Это для того что-бы принудительно сменить на новую версию
     * */
    res.data?.data.widgets.body
      .flatMap((g) => g.widgets)
      ?.filter((w) => 'value' in w && w.value === 'data.birthday')
      .forEach((w: IBirthdayWidget) => {
        w.text = 'data.formattedBirthday.value';
        w.name = null;
        w.value = null;
        w.hasPrivacy = true;
        w.required = true;
      });

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Получение полей автокомплита
 * */
export const getAutocompleteFields = request.custom(async ({ api, data }) => {
  try {
    const res = await api.get(
      `/api/admin/autocomplete/list?count=1000&page=1&sort[0][id]=createdAt&sort[0][value]=ASC&filters[0][id]=field&filters[0][value]=${data}`,
    );

    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Добавление поля в автокомплит
 * */
export const createAutocompleteField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/autocomplete/values/create',
      { ...data },
    );

    return { data: res };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Удаление поля из списка автокомплитов
 * */
export const removeAutocompleteField = request.custom(async ({ api, data }) => {
  try {
    const res = await api.delete<any>(
      `/api/admin/autocomplete/value/delete?id=${data}`,
    );

    return { data: res };
  } catch (err) {
    return { error: err };
  }
});

export const mobileProfileGet = request.card<any>(async ({ api, data, parseError }) => {
  const [view, translate, additions] = await Promise.all([
    // Получаем view
    await viewGet(''),
    // Получаем переводы
    await translatesGet(''),
    // Получаем доп. поля
    await additionsGet(''),
  ]);

  if (view?.error || translate?.error || additions?.error) {
    return { error: 'error' };
  }

  view.data.widgets.body.forEach((group) => {
    group.widgets.forEach((widget) => {
      const foundAddition = additions.data.find((a) => Object.keys(widget).some((key) => widget[key] === a.formCode));
      if (!widget.id) {
        widget.id = uuid.v4();
      }
      if (foundAddition) {
        widget.additions = foundAddition;
      }
    });
  });

  return {
    data: {
      editorLayout: {
        id: view.data.id,
        ...view.data.widgets,
        data: {
          id: 'data.id',
          name: 'data.name',
        },
      },
    },
  };
});

/**
 * Создание нового additional поля
 * */
export const createNewField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/additional_fields/create',
      { ...data },
    );

    // Обновление списока всех доп.полей
    // const additionalFields = await additionsGet('');
    // if (additionalFields.error) {
    //   return { error: additionalFields.error };
    // }

    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Обновление additional поля
 */
export const updateAdditionalField = request.custom(async ({ api, data, parseError }) => {
  try {
    const res = await api.patch<any>(
      '/api/admin/additional_fields/update',
      { ...data },
    );
    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Сохранение view(мп)
 * */
const saveView = request.custom(async ({ api, data }) => {
  try {
    const res = await api.put(
      '/api/admin/view',
      {
        id: data.id,
        code: 'user_profile',
        title: 'Профиль пользователя',
        widgets: {
          ...data,
          // ...defaultProfile.widgets,
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Сохранение admin-view
 * */
const saveAdminView = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post(
      '/api/admin/admin-view',
      {
        code: 'user_profile',
        json: {
          ...parseForAdmin(data),
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Сохранение представления для редактирования в мп
 * */
const saveMobileEdit = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post(
      '/api/admin/admin-view',
      {
        code: 'mp_profile_form',
        json: {
          ...parseForMobile(data),
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Обновление поля userBadge.
 * Тк он находится отдельно (в header)
 * */
const saveBadge = async (data: IViewWidgets) => {
  const badge = data.header.find((widget) => widget.type === 'header_badge_widget');
  const badgeField = AdditionsList?.find((addition) => addition.code === 'userBadge');

  try {
    await updateAdditionalField({
      ...badgeField,
      description: badge?.label,
      visibleInList: !!badge,
    });
  } catch (error) {
    return { error };
  }

  return { data: 'ok' };
};

export const mobileProfileUpdate = request.custom<any>(async ({ api, data }) => {
  const store = useEditorStore();
  const deepCopyPayload = JSON.parse(JSON.stringify(data.data.editorLayout)) as IViewWidgets;

  // Собираем массив с отредактированными доп.полями
  const listForUpdate = deepCopyPayload.body.flatMap((group) => group.widgets.map((widget) => {
    if ('additions' in widget && widget?.touch && !widget?.isNew) {
      return widget.additions;
    }
  }).filter(Boolean));

  // Собираем массив с новыми доп.полями
  const listForCreate = deepCopyPayload.body.flatMap((group) => group.widgets.map((widget) => {
    if ('additions' in widget && widget?.isNew) {
      return widget.additions;
    }
  }).filter(Boolean));

  // Собираем поля автокомплита которые нужно удалить и удаляем
  const listAutocompleteForDelete = store.autocompleteList.value.flatMap((item: any) => item.values?.filter((v: any) => v.value === '')).filter(Boolean);
  if (listAutocompleteForDelete.length) {
    await Promise.all(listAutocompleteForDelete.map(async (item: any) => {
      await removeAutocompleteField(item.id);
    }));
  }

  // Удаления доп.поля как такого нет, но его надо сделать поле необязательным прежде чем отвязывать
  if (store.removedResidentFieldIdsList.value.length) {
    await Promise.all(store.removedResidentFieldIdsList.value.map(async (id: string) => {
      await updateAdditionalField({
        id,
        isUserRequired: false,
        isAdminRequired: false,
      });
    }));
  }

  // Собираем поля автокомплита которые нужно добавить и добавляем
  const listAutocompleteForCreate = store.autocompleteList.value.reduce((acc, obj) => {
    const filteredValues = obj.values?.filter((val) => val.id === '').map((filteredVal) => filteredVal.value);
    if (filteredValues?.length) {
      acc.push({ field: obj.field, values: filteredValues });
    }
    return acc;
  }, []);
  if (listAutocompleteForCreate) {
    await Promise.all(listAutocompleteForCreate.map(async (item: any) => {
      await createAutocompleteField(item);
    }));
  }

  // Обновляем доп.поля
  if (listForUpdate.length) {
    await Promise.all(listForUpdate.map(async (addition) => {
      await updateAdditionalField(addition);
    }));
  }
  // Создаем доп.поля
  if (listForCreate.length) {
    await Promise.all(listForCreate.map(async (addition) => {
      await createNewField(addition);
    }));
  }

  // Удаляем лишнее
  const outView = {
    ...deepCopyPayload,
    body: deepCopyPayload.body.map((group) => ({
      ...group,
      widgets: group.widgets.map(({
        isNew, touch, additions, ...widget
      }) => widget),
    })),
  };

  // Сохраняем представления
  const res = await Promise.all([
    // Сохраняем view
    await saveView(outView),
    // Сохраняем admin-view
    await saveAdminView(deepCopyPayload),
    // Сохраняем редактирование в мобилке
    await saveMobileEdit(deepCopyPayload),
    // // Отдельно обновляем Badge в Additional листе
    await saveBadge(deepCopyPayload),

  ]).then(([view]) => ({
    view,
  }));

  if (res.view.error) {
    return { error: 'Ошибка' };
  }

  // window.location.reload();

  return { data: res.view };
});
