import { action, tablePageController, translate } from 'magner';
import { downloadReport, reportsRead } from 'features/reports/partners/requests';
import { searchResidents } from 'features/settings/notification/requests/hubstr';
import ResidentLink from 'features/reports/user-activity/residentLink.vue';
import { autocompleteDepartment } from 'features/residents/requests/hubstr';
import { subtractMonthDate } from '~/utils/format-date';

const reportsPartnersConfig = tablePageController<any>({
  header: {
    title: translate('hubstr.reports.table.partners.title'),
  },
  request: reportsRead,
  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: translate('hubstr.reports.table.not_found'),
    columns: [
      {
        prop: 'userId',
        label: translate('hubstr.reports.table.partners.userId'),
        view: {
          type: 'custom',
          component: () => ResidentLink,
        },
      },
      {
        prop: 'loyaltyView',
        label: translate('hubstr.reports.table.partners.loyaltyView'),
      },
      {
        prop: 'loyaltyShared',
        label: translate('hubstr.reports.table.partners.loyaltyShared'),
      },
    ],
  },
  filters: {
    actions: [
      {
        type: 'action',
        props: {
          text: translate('hubstr.reports.table.downloadList'),
          type: 'primary',
          hidden: (data) => {
            if (data.state.dateStart instanceof Date) {
              const d = new Date(data.state.dateStart);
              data.state.dateStart = `${d.getMonth() + 1}.${d.getDate()}.${d.getFullYear()}`;
            }
            return !data.state.dateStart.length || !data.state.department.length;
          },
        },
        // @ts-ignore
        action: action(async ({ data }) => {
          if (data.filters.department.length && data.filters.dateStart.length) {
            await downloadReport({ ...data, format: 'xlsx' });
          }
        }),
      },
    ],

    saveToLocalStorage: true,

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersInSeparatePanel: false,
    filtersData: {
      department: '',
      users: '',
      dateStart: '',
      dateEnd: '',
    },
    sort: {},

    layout: [
      {
        type: 'select',
        name: 'department',
        options: [],
        label: translate('hubstr.reports.filters.department.label'),
        props: {
          required: true,
          valueKey: 'id',
          labelKey: 'name',
          filterable: true,
          remote: true,
          remoteMethod: autocompleteDepartment,
          placeholder: translate('hubstr.reports.filters.department.placeholder'),
          multiple: true,
          collapseTags: true,
          clearable: true,
        },
        validation: [{
          type: 'empty-required' as 'empty',
          trigger: 'change',
        }],
      },
      {
        type: 'select',
        name: 'users',
        dataType: 'array',
        options: [],
        label: translate('hubstr.reports.filters.users.label'),
        props: {
          disabled: (data: { state: {department: string} }) => !data.state.department.length,
          multiple: true,
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: searchResidents,
          placeholder: translate('hubstr.reports.filters.users.placeholder'),
        },
      },
      {
        type: 'datetime',
        name: 'dateStart',
        dataType: 'date',
        label: translate('hubstr.reports.filters.dateStart.label'),
        props: {
          required: true,
          type: 'date',
          format: 'DD.MM.YYYY',
          placeholder: translate('hubstr.reports.filters.dateStart.placeholder'),
        },
        validation: [{
          type: 'empty-required' as 'empty',
          trigger: 'change',
        }],
      },
      {
        type: 'datetime',
        name: 'dateEnd',
        dataType: 'date',
        label: translate('hubstr.reports.filters.dateEnd.label'),
        hint: translate('hubstr.reports.filters.dateEnd.hint'),
        props: {
          type: 'date',
          format: 'DD.MM.YYYY',
          placeholder: translate('hubstr.reports.filters.dateEnd.placeholder'),
        },
      },
    ],
  },
});

export default reportsPartnersConfig;
