<template>
  <el-row class="row">
    <el-button class="exportUserList" type="primary" @click="handleClick">
      Сформировать отчет
    </el-button>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent
} from 'vue';
import { saveUserList } from 'features/reports/user-list/requests';

export default defineComponent({
  name: 'EventsUserList',
  methods: {
    async handleClick() {
      await saveUserList();
    }
  },
});
</script>

<style>
.row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.exportUserList {
  max-width: 250px;
}
</style>