import { cardPageController, translate } from 'magner';
import EventsUsersList from './eventsUserListDownloadCard.vue';

const reportsUserListConfig = cardPageController({
  getRequest: () => ({}),
  header: {
    title: translate('hubstr.reports.table.user_list.title'),
  },
  form: {
    fullDataOnUpdate: true,
    actions: [],
    layout: [
      {
        type: 'custom',
        name: 'UserListDownloadPage',
        component: () => EventsUsersList,
        props: {},
      },
    ],
  },
});

export default reportsUserListConfig;
