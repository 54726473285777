import {
  actionCard, openModal, tablePageController, translate,
} from 'magner';
import residentsTable from 'features/residents/table/hubstr';
import residentsFilters from 'features/residents/filters/hubstr';
import { Resident } from 'features/residents/types/hubstr';
import { residentRead } from 'features/residents/requests/hubstr';
import ImportResidentDialog from 'features/residents/residents/importResidentsDialog.vue';

const residentsConfig = tablePageController<Resident>({
  header: {
    title: translate('hubstr.resident.table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'default' as any,
          text: translate('gaz.import.button'),
        },
        emits: 'update-table',
        action: actionCard<any>(async () => {
          try {
            await openModal<any>({
              type: 'custom',
              handleBeforeClose: false,
              customClass: 'modal-max-width modal-max-width__400',
              component: () => ImportResidentDialog,
              props: {},
            });
            return false;
          } catch (e) {
            if (e !== null) {
              return translate('gaz.import.error');
            }
          }
        }),
      },
      {
        type: 'link',
        to: { name: 'resident', params: { id: 'new' } },
        props: {
          type: 'primary',
          text: translate('hubstr.resident.filters.create_new'),
        },
      },
    ],
  },
  request: residentRead,
  table: residentsTable as any,
  filters: residentsFilters,
});

export default residentsConfig;
