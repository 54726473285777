import { request } from '~/utils/request';
import { API_URL } from '~/constants';

export const saveUserList = request.custom(async ({ lstorage }) => {
  try {
    const token = lstorage.read('token');
    fetch(`${API_URL}/api/admin/analytics/gaz/export/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // @ts-ignore
        a.download = 'отчет_партнеры.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
  catch (e) {
    console.error(e);
  }
});
